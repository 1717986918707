import type { UpdateMeInput } from '@/graphql/types';
import type { localeType } from '@/modules/users';
import type { MeDetail_UserFragment } from '@/modules/users/components/MeDetail.generated';
import { ACCOUNT } from '@/utils/i18n/constants';
import useTranslation from '@/utils/i18n/useTranslation';
import { gql } from '@apollo/client';
import {
  Box,
  Button,
  Flex,
  ListItem,
  Select,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import { Link } from '@remix-run/react';
import type { FC } from 'react';
import MeDetailNotification from './MeDetailNotification';

type MeDetailProps = {
  me: MeDetail_UserFragment;
  handleSave: (input: UpdateMeInput) => void;
};

gql`
fragment MeDetail_User on User {
  id
  name
  email
  phoneNumber
  locale
  setting {
    ...MeDetailNotification_UserSetting
  }
}
`;

const MeListItem = (props: {
  label: string;
  value: string | undefined | null;
}) => (
  <ListItem>
    <Flex
      direction={{
        base: 'column',
        md: 'row',
      }}
    >
      {props.label}:<Text ml={2}>{props.value}</Text>
    </Flex>
  </ListItem>
);
const autoLocale = 'auto';
type autoLiteral = 'auto';

type localeSelectType = localeType | autoLiteral;

const MeDetail: FC<MeDetailProps> = (props: MeDetailProps) => {
  const { handleSave, me } = props;
  const { t, t_ns } = useTranslation(ACCOUNT);

  const { name, email, phoneNumber, locale } = me;
  const localeValue = locale || autoLocale;

  const onChangeLocale = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const locale = e.target.value as localeSelectType;
    handleSave({
      locale: locale === autoLocale ? null : locale,
    });
  };

  return (
    <Box w='full' h='full'>
      <Tabs w='full' h='full' px={5}>
        <TabList>
          <Tab>{t_ns('tabs.info')}</Tab>
          <Tab>{t_ns('tabs.lang')}</Tab>
          <Tab>{t_ns('tabs.notification')}</Tab>
        </TabList>

        <TabPanels border='1px solid' mt={5} borderColor='neutral.300' h='calc(100% - 42px - 22px)'>
          <TabPanel>
            <Flex direction='column'>
              <UnorderedList listStyleType='none' m={0}>
                <MeListItem label={t('form.username')} value={name} />
                <MeListItem label={t('form.email')} value={email} />
                <MeListItem label={t('form.phone')} value={phoneNumber} />
              </UnorderedList>
            </Flex>
            <Box mt={5}>
              <Button size='sm' colorScheme='red' as={Link} to='/logout'>
                {t_ns('logout')}
              </Button>
            </Box>
          </TabPanel>
          <TabPanel>
            <Flex direction='column'>
              <Select value={localeValue} onChange={onChangeLocale}>
                <option value='auto'>{t_ns('options.auto')}</option>
                <option value='ja'>{t_ns('options.japanese')}</option>
                <option value='en'>{t_ns('options.english')}</option>
              </Select>
            </Flex>
          </TabPanel>
          <TabPanel h='full' overflow='auto'>
            {me.setting && (
              <MeDetailNotification
                defaultValue={me.setting}
                handleSave={(setting) => handleSave({ setting })}
              />
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default MeDetail;
