import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Text,
  VStack,
} from '@chakra-ui/react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { MdContentCopy } from 'react-icons/md';

import type { UpdateUserInput } from '@/graphql/types';
import { POSITIONS } from '@/modules/users';
import {
  type UserForm_UserFragment,
  useUserFormSuspenseQuery,
} from '@/modules/users/components/UserForm.generated';
import { useToast } from '@/utils/atoms/toast';
import { USERS } from '@/utils/i18n/constants';
import useTranslation from '@/utils/i18n/useTranslation';
import { cleanPhoneNumber } from '@/utils/phoneNumber';
import { gql } from '@apollo/client';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

gql`
fragment UserForm_User on User {
  id
  name
  email
  phoneNumber
  officeRoles {
    id
    role {
      id
      position
    }
  }
  loginMethod {
    id
  }
  accessPolicy {
    id
  }
}
query UserForm {
  loginMethodsForCompany {
    id
    type
    name
  }
  accessPoliciesForCompany {
    id
    name
  }
}
`;

type UserFormData = {
  name: string;
  password?: string;
  email: string;
  phoneNumber?: string;
  position: string;
  loginMethodId?: number;
  accessPolicyId?: number;
};

export type UserFormHandle = {
  submit(callback: (data: Omit<UpdateUserInput, 'id'>) => Promise<void>): Promise<void>;
};

type UserFormProps = {
  user?: UserForm_UserFragment;
};

const PASSWORD_LENGTH = 16;
const JAPANESE_COUNTRY_CODE = '+81';

const defaultValues: UserFormData = {
  name: '',
  email: '',
  password: undefined,
  position: POSITIONS[1].value,
};

const UserForm = forwardRef<UserFormHandle, UserFormProps>(function UserForm(props, ref) {
  const { user } = props;

  const { toast } = useToast();
  const { t, t_errors, t_toasts, t_ns } = useTranslation(USERS);
  const {
    data: { loginMethodsForCompany, accessPoliciesForCompany },
  } = useUserFormSuspenseQuery();

  const isEditing = !!user;

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isPasswordDisabled, setIsPasswordDisabled] = useState<boolean>(user !== undefined);

  const handleShowClick = () => setShowPassword(!showPassword);

  const {
    handleSubmit,
    register,
    setValue,
    control,
    watch,
    formState: { errors },
    getValues,
  } = useForm<UserFormData>({
    defaultValues: user
      ? {
          name: user.name,
          email: user.email,
          password: undefined,
          position: user.officeRoles[0]?.role?.position ?? POSITIONS[1].value,
          phoneNumber: user.phoneNumber ? cleanPhoneNumber(user.phoneNumber) : undefined,
          loginMethodId: user.loginMethod?.id,
          accessPolicyId: user.accessPolicy?.id,
        }
      : defaultValues,
  });

  useImperativeHandle(ref, () => ({
    submit: async (callback) => {
      await handleSubmit(async (data) => {
        if (data.phoneNumber && data.phoneNumber?.length !== 11) {
          toast({
            title: t_errors('phone-format-incorrect'),
            status: 'error',
          });
          return;
        }

        await callback({
          name: data.name,
          password: data.password || undefined,
          email: data.email,
          phoneNumber: data.phoneNumber ? `${JAPANESE_COUNTRY_CODE} ${data.phoneNumber}` : null,
          oldPosition: user?.officeRoles?.[0]?.role?.position,
          position: data.position,
          loginMethodId: data.loginMethodId ?? null,
          accessPolicyId: data.accessPolicyId ?? null,
        });
      })();
    },
  }));

  const resetPassword = () => {
    setIsPasswordDisabled(false);
    setValue('password', '');
  };

  const generatePassword = () => {
    const lowercaseChars = 'abcdefghijklmnopqrstuvwxyz';
    const uppercaseChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const numericChars = '0123456789';
    const specialChars = '!@#$%^&*()_-+=';
    const charset = lowercaseChars + uppercaseChars + numericChars + specialChars;

    let password = '';
    for (let i = 0; i < PASSWORD_LENGTH; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      password += charset[randomIndex];
    }
    setValue('password', password);
  };

  const copyToClipboard = () => {
    const { clipboard } = navigator;
    if (!clipboard) {
      return;
    }
    const { password = '' } = getValues();
    clipboard
      .writeText(password)
      .then(() => {
        toast({
          title: t_toasts('success.users.password-copied'),
          status: 'success',
        });
      })
      .catch((error) => {
        console.error(error);
        toast({
          title: t_errors('failed.users.failed-to-copy-password'),
          status: 'error',
        });
      });
  };

  return (
    <Box bg='neutral.50'>
      <VStack align='stretch'>
        <form>
          <Box>
            <FormControl isInvalid={!!errors.name} px={4} py={2} bg='white'>
              <FormLabel color='neutral.800'>
                {t('form.username')}
                <Text as='span' color='error.500'>
                  *
                </Text>
              </FormLabel>
              <Input
                {...register('name', {
                  required: t('warning.please-enter-username'),
                })}
                autoComplete='off'
              />
              <FormErrorMessage>{errors.name && errors.name.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.email} px={4} py={2} bg='white'>
              <FormLabel color='neutral.800'>
                {t('form.email')}
                <Text as='span' color='error.500'>
                  *
                </Text>
              </FormLabel>

              <Input
                {...register('email', {
                  required: t('warning.please-enter-email'),
                  pattern: {
                    value: /\S+@\S+\.\S+/, // RFC対応していないので、正規表現を見直す必要あり
                    message: t_errors('email-format-incorrect'),
                  },
                })}
                autoComplete='off'
              />
              <FormErrorMessage>{errors.email && errors.email.message}</FormErrorMessage>
            </FormControl>
            <FormControl px={4} py={2} bg='white'>
              <FormLabel color='neutral.800'>{t('form.mobile-phone')}</FormLabel>
              <Input
                maxLength={13}
                minLength={10}
                {...register('phoneNumber')}
                autoComplete='off'
              />
            </FormControl>
            <FormControl px={4} py={2} bg='white'>
              <FormLabel color='neutral.800'>{t_ns('user-role')}</FormLabel>
              <Select {...register('position')}>
                {POSITIONS.map((position) => (
                  <option key={position.value} value={position.value}>
                    {t(`users.roles.${position.label}`)}
                  </option>
                ))}
              </Select>
            </FormControl>
            {accessPoliciesForCompany.length > 0 && (
              <FormControl px={4} py={2} bg='white'>
                <FormLabel color='neutral.800'>{t_ns('access-policy')}</FormLabel>
                <Controller
                  control={control}
                  name='accessPolicyId'
                  render={({ field }) => (
                    <Select
                      value={field.value ? String(field.value) : ''}
                      onChange={(e) =>
                        field.onChange(e.target.value.length === 0 ? null : Number(e.target.value))
                      }
                    >
                      <option key='default' value=''>
                        {t_ns('access-policies.default')}
                      </option>
                      {accessPoliciesForCompany.map((policy) => (
                        <option key={policy.id} value={policy.id}>
                          {policy.name}
                        </option>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            )}
            {loginMethodsForCompany.length > 0 && (
              <FormControl px={4} py={2} bg='white'>
                <FormLabel color='neutral.800'>{t_ns('login-method')}</FormLabel>
                <Controller
                  control={control}
                  name='loginMethodId'
                  render={({ field }) => (
                    <Select
                      value={field.value ? String(field.value) : ''}
                      onChange={(e) => {
                        // SAML -> Emailログインに切り替えた時にはパスワードを設定させたいので
                        field.onChange(e.target.value.length === 0 ? null : Number(e.target.value));
                        setIsPasswordDisabled(e.target.value.length > 0);
                      }}
                    >
                      <option key='password' value=''>
                        {t_ns('login-methods.password')}
                      </option>
                      {loginMethodsForCompany.map((loginMethod) => (
                        <option key={loginMethod.id} value={loginMethod.id}>
                          {loginMethod.name}
                        </option>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            )}

            {!watch('loginMethodId') && (
              <FormControl isInvalid={!!errors.password} px={4} py={2} bg='white'>
                <FormLabel color='neutral.800'>
                  {t('form.password')}
                  <Text as='span' color='error.500'>
                    *
                  </Text>
                  {isEditing && (
                    <Button
                      variant='ghost'
                      color='primary.400'
                      size='sm'
                      fontWeight='normal'
                      fontSize='md'
                      m={0}
                      px={1}
                      onClick={() => resetPassword()}
                    >
                      ({t('actions.reset')})
                    </Button>
                  )}
                </FormLabel>
                <Flex
                  justifyItems='center'
                  alignItems='center'
                  direction={{ base: 'column', md: 'row' }}
                  gap={3}
                >
                  <InputGroup w='full'>
                    <Input
                      disabled={isPasswordDisabled}
                      autoComplete='new-password'
                      type={showPassword ? 'text' : 'password'}
                      {...register('password', {
                        required: {
                          value: !isPasswordDisabled,
                          message: t('warning.please-enter-password'),
                        },
                        minLength: {
                          value: 6,
                          message: t_errors('password-min-length', {
                            length: 6,
                          }),
                        },
                      })}
                    />

                    <InputRightElement mr={5}>
                      {!isPasswordDisabled && (
                        <IconButton
                          aria-label='Copy to clipboard'
                          size='sm'
                          variant='ghost'
                          color='neutral.500'
                          icon={showPassword ? <FaEyeSlash /> : <FaEye />}
                          onClick={handleShowClick}
                        />
                      )}
                      <IconButton
                        aria-label='Copy to clipboard'
                        size='sm'
                        variant='ghost'
                        color='neutral.500'
                        onClick={() => copyToClipboard()}
                        icon={<MdContentCopy />}
                      />
                    </InputRightElement>
                  </InputGroup>
                  {!isPasswordDisabled && (
                    <Button
                      ml='auto'
                      size='sm'
                      variant='outline'
                      colorScheme='primary'
                      onClick={() => generatePassword()}
                    >
                      {t('actions.generate')}
                    </Button>
                  )}
                </Flex>
                <FormErrorMessage>{errors.password && errors.password.message}</FormErrorMessage>
                {!isEditing && <FormHelperText>{t_ns('sign-up-helper')}</FormHelperText>}
              </FormControl>
            )}
          </Box>
        </form>
      </VStack>
    </Box>
  );
});

export default UserForm;
