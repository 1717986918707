export type localeType = 'en' | 'ja';
export interface IDisplayUser {
  id: string;
  name: string;
}

export type NotificationType = 'all' | 'none' | 'individual';

export type WorkOrderPriorityThresholdType = 'none' | 'low' | 'medium' | 'high' | 'all';

export const POSITIONS = [
  { value: 'admin', label: 'organization-manager' },
  { value: 'member', label: 'organization-member' },
];
