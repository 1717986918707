import * as Types from '../../../graphql/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserForm_UserFragment = (
  { __typename?: 'User' }
  & Pick<Types.User, 'id' | 'name' | 'email' | 'phoneNumber'>
  & { officeRoles: Array<(
    { __typename?: 'UserOfficeRole' }
    & Pick<Types.UserOfficeRole, 'id'>
    & { role: (
      { __typename?: 'Role' }
      & Pick<Types.Role, 'id' | 'position'>
    ) }
  )>, loginMethod?: Types.Maybe<(
    { __typename?: 'LoginMethod' }
    & Pick<Types.LoginMethod, 'id'>
  )>, accessPolicy?: Types.Maybe<(
    { __typename?: 'AccessPolicy' }
    & Pick<Types.AccessPolicy, 'id'>
  )> }
);

export type UserFormQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UserFormQuery = (
  { __typename?: 'Query' }
  & { loginMethodsForCompany: Array<(
    { __typename?: 'LoginMethod' }
    & Pick<Types.LoginMethod, 'id' | 'type' | 'name'>
  )>, accessPoliciesForCompany: Array<(
    { __typename?: 'AccessPolicy' }
    & Pick<Types.AccessPolicy, 'id' | 'name'>
  )> }
);

export const UserForm_UserFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"UserForm_User"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"User"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"phoneNumber"}},{"kind":"Field","name":{"kind":"Name","value":"officeRoles"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"role"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"position"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"loginMethod"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}},{"kind":"Field","name":{"kind":"Name","value":"accessPolicy"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode;
export const UserFormDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"UserForm"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"loginMethodsForCompany"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"type"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}},{"kind":"Field","name":{"kind":"Name","value":"accessPoliciesForCompany"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useUserFormQuery__
 *
 * To run a query within a React component, call `useUserFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserFormQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserFormQuery(baseOptions?: Apollo.QueryHookOptions<UserFormQuery, UserFormQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserFormQuery, UserFormQueryVariables>(UserFormDocument, options);
      }
export function useUserFormLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserFormQuery, UserFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserFormQuery, UserFormQueryVariables>(UserFormDocument, options);
        }
export function useUserFormSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UserFormQuery, UserFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserFormQuery, UserFormQueryVariables>(UserFormDocument, options);
        }
export type UserFormQueryHookResult = ReturnType<typeof useUserFormQuery>;
export type UserFormLazyQueryHookResult = ReturnType<typeof useUserFormLazyQuery>;
export type UserFormSuspenseQueryHookResult = ReturnType<typeof useUserFormSuspenseQuery>;
export type UserFormQueryResult = Apollo.QueryResult<UserFormQuery, UserFormQueryVariables>;