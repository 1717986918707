import FixedDataTable from '@/common/components/FixedDataTable';
import { POSITIONS } from '@/modules/users';
import type { UsersTable_UserFragment } from '@/modules/users/components/UsersTable.generated';
import { formatDateToMD_or_YYYYMD } from '@/utils/date/date';
import { USERS } from '@/utils/i18n/constants';
import useTranslation from '@/utils/i18n/useTranslation';
import { useScreenInfos } from '@/utils/mobiles/useScreenInfos';
import { gql } from '@apollo/client';
import { IconButton, Menu, MenuButton, MenuItem, MenuList, TableContainer } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { FC } from 'react';
import { MdMoreVert } from 'react-icons/md';

const columnHelper = createColumnHelper<UsersTable_UserFragment>();

gql`
fragment UsersTable_User on User {
  id
  name
  email
  phoneNumber
  lastLogin
  officeRoles {
    id
    role {
      id
      position
    }
  }
}
`;

export type UsersTableProps = {
  users: UsersTable_UserFragment[];
  handleUpdateUserClick: (userId: string) => void;
  handleDeleteUserClick: (userId: string) => void;
};

const getOfficeRolePosition = (officeRoles: UsersTable_UserFragment['officeRoles']) => {
  if (officeRoles.length > 0) {
    return POSITIONS.filter((position) => position.value === officeRoles[0].role.position)[0].label;
  }

  return '';
};

const UsersTable: FC<UsersTableProps> = (props: UsersTableProps) => {
  const { users: usersTableData, handleUpdateUserClick, handleDeleteUserClick } = props;
  const { t, t_ns } = useTranslation(USERS);
  const { isMobile } = useScreenInfos();

  const columns = [
    columnHelper.accessor('name', {
      cell: (info) => info.getValue(),
      maxSize: isMobile ? 100 : undefined,
      header: t('form.username'),
      enableSorting: true,
    }),
    columnHelper.accessor('email', {
      cell: (info) => info.getValue(),
      header: t('form.email'),
      enableSorting: true,
    }),
    columnHelper.accessor('phoneNumber', {
      cell: (info) => info.getValue(),
      header: t('form.phone'),
      enableSorting: true,
    }),
    columnHelper.accessor('officeRoles', {
      cell: (info) => {
        const value = info.getValue();
        const officeRole = getOfficeRolePosition(value);
        return value && officeRole ? t(`users.roles.${officeRole}`) : '';
      },
      header: t_ns('user-role'),
      enableSorting: true,
    }),
    columnHelper.accessor('lastLogin', {
      cell: (info) => formatDateToMD_or_YYYYMD(info.getValue()),
      header: t_ns('last-login'),
      enableSorting: true,
    }),
    columnHelper.display({
      id: 'actions',
      maxSize: 16,
      cell: (info) => (
        <UserRowActions
          user={info.row.original}
          handleUpdateUserClick={handleUpdateUserClick}
          handleDeleteUserClick={handleDeleteUserClick}
        />
      ),
      meta: {
        isClickDisabled: true,
      },
    }),
  ];

  return (
    <TableContainer
      overflowY='auto'
      overflowX='unset'
      maxH='calc(100vh - 50px)'
      width='full'
      whiteSpace='pre-wrap'
    >
      <FixedDataTable
        columns={columns}
        data={usersTableData}
        onClickTarget={handleUpdateUserClick}
      />
    </TableContainer>
  );
};

type UserRowActionsProps = {
  user: UsersTable_UserFragment;
  handleUpdateUserClick: (userId: string) => void;
  handleDeleteUserClick: (userId: string) => void;
};

const UserRowActions = (props: UserRowActionsProps) => {
  const { user, handleUpdateUserClick, handleDeleteUserClick } = props;
  const { t } = useTranslation();

  return (
    <Menu>
      <MenuButton
        as={IconButton}
        size='sm'
        colorScheme='gray'
        variant='ghost'
        fontSize={20}
        aria-label='User Table Action'
        icon={<MdMoreVert />}
      />
      <MenuList>
        <MenuItem
          onClick={() => handleUpdateUserClick(user.id)}
          fontSize='sm'
          fontWeight='normal'
          color='primary'
        >
          {t('actions.edit')}
        </MenuItem>
        <MenuItem
          onClick={() => handleDeleteUserClick(user.id)}
          fontSize='sm'
          fontWeight='normal'
          color='primary'
        >
          {t('actions.delete')}
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default UsersTable;
