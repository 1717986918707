import { UpdateMeInput } from '@/graphql/types';
import { WorkOrderPriorityThresholdType } from '@/modules/users';
import type { MeDetailNotification_UserSettingFragment } from '@/modules/users/components/MeDetailNotification.generated';
import { ACCOUNT } from '@/utils/i18n/constants';
import useTranslation from '@/utils/i18n/useTranslation';
import { RemoveSuffix } from '@/utils/types/types';
import { gql } from '@apollo/client';
import { omitDeep } from '@apollo/client/utilities/common/omitDeep';
import { Box, Divider, FormControl, FormLabel, Heading, Switch, VStack } from '@chakra-ui/react';
import { FC, useEffect } from 'react';
import { Controller, FieldPathByValue, useForm } from 'react-hook-form';

gql`
fragment MeDetailNotification_UserSetting on UserSetting {
  workOrderNotificationSetting {
    priorityThreshold
    workOrderCreateEnabled
    workOrderAssignEnabled
    workOrderAssignToGroupMemberEnabled
    workOrderCompleteEnabled
    workOrderCommentCreateEnabled
    notifyEnabled
  }
  requestNotificationSetting {
    requestCreateEnabled
    requestApproveEnabled
    requestWithdrawnEnabled
    requestRejectEnabled
    requestAssignToGroupEnabled
    requestAssignToGroupMemberEnabled
    requestedWorkOrderCompleteEnabled
  }
  partNotificationSetting {
    replenishmentEnabled
  }
  chatNotificationSetting {
    messageCreateEnabled
  }
  checkListNotificationSetting {
    alertTriggeredEnabled
  }
}
`;

type MeDetailNotificationProps = {
  defaultValue: MeDetailNotification_UserSettingFragment;
  handleSave: (input: UpdateMeInput['setting']) => void;
};

const workOrderPriorityThreshold: Record<string, WorkOrderPriorityThresholdType> = {
  none: 'none',
  high: 'high',
  low: 'low',
  medium: 'medium',
  all: 'all',
};

type FormData = NonNullable<UpdateMeInput['setting']> & {
  workOrderNotificationSetting?: Omit<
    NonNullable<UpdateMeInput['setting']>['workOrderNotificationSetting'],
    'priorityThreshold'
  > & {
    highPriorityTaskCreateEnabled: boolean;
  };
};

type OptionGroupType = RemoveSuffix<keyof FormData, 'NotificationSetting'>;
type OptionGroup = {
  type: OptionGroupType;
  options: [
    FieldPathByValue<FormData, boolean | undefined | null>,
    string, // label
  ][];
};

const notificationOptions: OptionGroup[] = [
  {
    type: 'workOrder',
    options: [
      ['workOrderNotificationSetting.workOrderCreateEnabled', 'created'],
      ['workOrderNotificationSetting.workOrderAssignEnabled', 'assigned'],
      [
        'workOrderNotificationSetting.workOrderAssignToGroupMemberEnabled',
        'assigned-to-group-member',
      ],
      ['workOrderNotificationSetting.highPriorityTaskCreateEnabled', 'high-priority-task-created'],
      ['workOrderNotificationSetting.workOrderCompleteEnabled', 'completed'],
      ['workOrderNotificationSetting.workOrderCommentCreateEnabled', 'comment-created'],
      ['workOrderNotificationSetting.notifyEnabled', 'notified'],
    ],
  },
  {
    type: 'request',
    options: [
      ['requestNotificationSetting.requestCreateEnabled', 'created'],
      ['requestNotificationSetting.requestApproveEnabled', 'approved'],
      ['requestNotificationSetting.requestWithdrawnEnabled', 'withdrawned'],
      ['requestNotificationSetting.requestRejectEnabled', 'rejected'],
      ['requestNotificationSetting.requestAssignToGroupEnabled', 'assigned-to-group'],
      ['requestNotificationSetting.requestAssignToGroupMemberEnabled', 'assigned-to-group-member'],
      [
        'requestNotificationSetting.requestedWorkOrderCompleteEnabled',
        'requested-work-order-completed',
      ],
    ],
  },
  {
    type: 'chat',
    options: [['chatNotificationSetting.messageCreateEnabled', 'message-created']],
  },
  {
    type: 'part',
    options: [['partNotificationSetting.replenishmentEnabled', 'replenish']],
  },
  {
    type: 'checkList',
    options: [['checkListNotificationSetting.alertTriggeredEnabled', 'alert-triggered']],
  },
];

const MeDetailNotification: FC<MeDetailNotificationProps> = (props: MeDetailNotificationProps) => {
  const { defaultValue, handleSave } = props;
  const { t_ns } = useTranslation(ACCOUNT);

  const { handleSubmit, control, watch } = useForm<FormData>({
    defaultValues: {
      ...defaultValue,
      workOrderNotificationSetting: {
        highPriorityTaskCreateEnabled:
          defaultValue?.workOrderNotificationSetting?.priorityThreshold ===
          workOrderPriorityThreshold.high,
        ...defaultValue?.workOrderNotificationSetting,
      },
    },
  });

  useEffect(() => {
    const onSubmit = ({
      workOrderNotificationSetting,
      requestNotificationSetting,
      chatNotificationSetting,
      partNotificationSetting,
      checkListNotificationSetting,
    }: FormData) => {
      handleSave(
        omitDeep(
          {
            workOrderNotificationSetting: (() => {
              if (!workOrderNotificationSetting) return undefined;
              const { highPriorityTaskCreateEnabled, ...rest } = workOrderNotificationSetting;
              return {
                ...rest,
                priorityThreshold: highPriorityTaskCreateEnabled
                  ? workOrderPriorityThreshold.high
                  : workOrderPriorityThreshold.none,
              };
            })(),
            requestNotificationSetting: requestNotificationSetting,
            chatNotificationSetting: chatNotificationSetting,
            partNotificationSetting: partNotificationSetting,
            checkListNotificationSetting: checkListNotificationSetting,
          },
          '__typename'
        )
      );
    };
    const subscription = watch(() => handleSubmit(onSubmit)());
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch, handleSave]);

  return (
    <VStack spacing='24px' alignItems='left'>
      {notificationOptions.map(({ type, options }, index) => (
        <Box key={type}>
          <Heading size='md' mb={3}>
            {t_ns(`notification-options.${type}.title`)}
          </Heading>
          {options.map(([key, label]) => {
            return (
              <FormControl display='flex' alignItems='center' key={key} pl={4} w={{ md: '60%' }}>
                <FormLabel my='1' fontWeight='400' color='neutral.800' w='80%'>
                  {t_ns(`notification-options.${type}.${label}`)}
                </FormLabel>
                <Controller
                  control={control}
                  name={key}
                  render={({ field: { value, onChange } }) => (
                    <Switch
                      isChecked={value ?? false}
                      onChange={(e) => onChange(e.target.checked)}
                    />
                  )}
                />
              </FormControl>
            );
          })}
          {notificationOptions.length - 1 !== index && <Divider mt={3} />}
        </Box>
      ))}
    </VStack>
  );
};

export default MeDetailNotification;
