import { Table, TableCellProps, Tbody, Td, Th, Thead, Tr, chakra } from '@chakra-ui/react';
import {
  ColumnDef,
  SortingState,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { MdArrowDropDown, MdArrowDropUp } from 'react-icons/md';

type FixedDataTableProps<Data extends object> = {
  data: Data[];
  // biome-ignore lint/suspicious/noExplicitAny: any
  columns: ColumnDef<Data, any>[];
  // biome-ignore lint/suspicious/noExplicitAny: any
  onClickTarget?: (value: any) => void;
  defaultSortBy?: SortingState;
  headerWidth?: string;
  reverse?: boolean;
  cellCursor?: TableCellProps['cursor'];
};

export default function FixedDataTable<Data extends object>({
  data,
  columns,
  defaultSortBy = [],
  onClickTarget,
  reverse,
  headerWidth,
  cellCursor = 'pointer',
}: FixedDataTableProps<Data>) {
  const [sorting, setSorting] = useState<SortingState>(defaultSortBy);
  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
  });

  // biome-ignore lint/correctness/useExhaustiveDependencies: Disabled exhaustive-deps because we want to recompute the memoized value whenever the data changes
  const { headerGroups, bodyGroups } = useMemo(() => {
    const headerGroups: JSX.Element[][] = [];
    const bodyGroups: JSX.Element[][] = [];
    // biome-ignore lint/suspicious/noExplicitAny: any
    table.getHeaderGroups().forEach((headerGroup: any, headerGroupIndex: number) => {
      // biome-ignore lint/suspicious/noExplicitAny: any
      headerGroup.headers.forEach((header: any, headerIndex: number) => {
        // biome-ignore lint/suspicious/noExplicitAny: any
        const meta: any = header.column.columnDef.meta;
        const item = (
          <Th
            minW={headerWidth}
            width={headerWidth}
            pos={headerIndex === 0 || reverse ? 'sticky' : 'static'}
            left={0}
            whiteSpace='pre-wrap'
            backgroundColor='neutral.100'
            color='neutral.500'
            key={header.id}
            onClick={header.column.getToggleSortingHandler()}
            isNumeric={meta?.isNumeric}
            paddingInlineStart={{ base: '4', md: '6' }}
            paddingInlineEnd={{ base: '1', md: '6' }}
          >
            {flexRender(header.column.columnDef.header, header.getContext())}

            <chakra.span verticalAlign='middle' display='inline-flex'>
              {header.column.getIsSorted() ? (
                header.column.getIsSorted() === 'desc' ? (
                  <MdArrowDropDown aria-label='sorted descending' />
                ) : (
                  <MdArrowDropUp aria-label='sorted ascending' />
                )
              ) : null}
            </chakra.span>
          </Th>
        );
        const groups = reverse ? bodyGroups : headerGroups;
        const index = reverse ? headerIndex : headerGroupIndex;
        const group = groups[index] || [];
        group.push(item);
        groups[index] = group;
      });
    });
    // biome-ignore lint/suspicious/noExplicitAny: any
    table.getRowModel().rows.forEach((row: any, rowIndex: number) => {
      // biome-ignore lint/suspicious/noExplicitAny: any
      row.getVisibleCells().forEach((cell: any, cellIndex: number) => {
        // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
        // biome-ignore lint/suspicious/noExplicitAny: any
        const meta: any = cell.column.columnDef.meta;
        const item = (
          <Td
            cursor={cellCursor}
            key={cell.id}
            onClick={() => !meta?.isClickDisabled && onClickTarget && onClickTarget(row.original)}
            isNumeric={meta?.isNumeric}
            pos={cellIndex === 0 && !reverse ? 'sticky' : 'static'}
            left={0}
            minW={150}
            whiteSpace='pre-wrap'
            maxW={cell.column.columnDef?.maxSize}
            bg={cellIndex === 0 ? 'neutral.50' : undefined}
          >
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </Td>
        );
        const index = reverse ? cellIndex : rowIndex;
        const bodyGroup = bodyGroups[index] || [];
        bodyGroup.push(item);
        bodyGroups[index] = bodyGroup;
      });
    });
    return {
      headerGroups,
      bodyGroups,
    };
    // NOTE: Disabled exhaustive-deps because we want to recompute the memoized value whenever the data changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, table, headerWidth, reverse, onClickTarget]);

  return (
    <Table size='sm'>
      <Thead pos='sticky' top={0}>
        {headerGroups.map((headerGroup, i) => (
          <Tr key={i}>{headerGroup}</Tr>
        ))}
      </Thead>
      <Tbody>
        {bodyGroups.map((bodyGroup, i) => (
          <Tr key={i}>{bodyGroup}</Tr>
        ))}
      </Tbody>
    </Table>
  );
}
